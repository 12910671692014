<template>
  <div class="AllInactiveContacts">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol sm="6">
        <h1> Contacts archivés du cabinet </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="$router.push('/add-new-contact')"
          size="sm"
          shape="pill"
          color="outline-success">
            <CIcon name="cil-plus"/> Ajouter un nouveau contact
        </CButton>
      </CCol>
    </CRow>


    <CCard class="mt-3">
      <CCardBody>

        <CDataTable
          class="cursor-table"
          hover
          :items="inactiveContactsRender"
          :fields="inactiveContactsRenderFields"
          @row-clicked="goToContactPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #phone_number="{item}">
          <td v-if="item.phone_number">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.phone_number}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>
        <template #birthdate="{item}">
          <td>
           <span v-if="item.birthdate">{{$dayjs(item.birthdate).format('DD/MM/YYYY')}} </span>
           <span v-else> Non renseigné </span>
          </td>
        </template>

        </CDataTable>
        <CRow>
          <CCol class="text-center">
            <CButton
              v-if="getAllInactiveContactsUrl"
              @click="getAllInactiveContacts()"
              class="px-4"
              color="outline-dark">
              Voir plus
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { APIManagerConnected } from '@/api/APIManagerConnected'

import renderMixins from '@/mixins/renderMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'AllInactiveContacts',
  mixins: [
    renderMixins
  ],
  components: {
    Loading,
  },
  data: function () {
    return {
      // ------------- MISSION -----------
      inactiveContacts: [],
      inactiveContactsRender: [],
      inactiveContactsRenderFields: [
          { key: "first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "birthdate", label: "Date de naissance", tdClass: 'ui-helper-center' },
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
      ],
      isAllInactiveContactsLoading: false,


      basegetAllInactiveContactsUrl: apiBaseUrl + '/all-inactive-contacts',
      getAllInactiveContactsUrl: apiBaseUrl + '/all-inactive-contacts',
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllInactiveContactsLoading) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllInactiveContacts()
  },
  watch: {
    inactiveContacts: function (newContacts) {
      if (newContacts.length == 0) {
        this.inactiveContactsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newContacts.length; i++) {
          final_array.push(
            {
              'id': newContacts[i].id,
              'sex': newContacts[i].sex,
              'client': newContacts[i].client,
              'first_name': newContacts[i].first_name,
              'last_name': newContacts[i].last_name,
              'birthdate': newContacts[i].birthdate,
              'email': newContacts[i].email,
              'phone_country_code': newContacts[i].phone_country_code,
              'phone_number': newContacts[i].phone_number,
              'observations': newContacts[i].observations,
            }
          )
        }
        this.inactiveContactsRender = final_array
      }
    },
  },
  methods: {
    getAllInactiveContacts () {
      this.isAllInactiveContactsLoading = true

      apiManagerConnected.getAllInactiveContacts(this.token, this.getAllInactiveContactsUrl)
      .then((result) => {
        this.inactiveContacts = this.inactiveContacts.concat(result.data.results)
        this.getAllInactiveContactsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllInactiveContactsLoading = false
      })
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToContactPage(item) {
      this.$router.push({ name: 'Visualiser votre contact', params: { id: item.id } })
    }

  }

}
</script>
